import React from 'react'
import {MasterBtn, MasterPatientBtn} from "../Buttons/MasterButtons";
import {CancelRedBtn} from "../Buttons/CustomButtons";
import {getPatientsList} from "../../services/HomeService";
import {runPatient} from "../../services/DiagnosisService";
import {Diagnosis} from "../Diagnosis/Diagnosis";
import {Redirect} from "react-router-dom";

const patients_list = ['patient_test_001', 'patient_test_002', 'patient_test_003', 'patient_test_004', 'patient_test_005']
//const test_bool = [true, false, true, true, true]

export const MasterChoices = ({startUploadPatient}) => {
    const [status, setStatus] = React.useState(0)
    /**
     * status = 0 : waiting for user to choose an option
     * status = 1 : waiting for user to choose a patient
     * status = 2 : user chose a patient            => waiting for diagnosis to finish
     * status = 3 : diagnosis finished and ok       => Review page must be displayed
     * status = 4 : diagnosis finished but not ok   => error message displayed
     */
    const [boolList, setBoolList] = React.useState(null)
    const [patientList, setPatientList] = React.useState(null)
    const [unauthorised, setUnauthorised] = React.useState(false)
    const [chosenPatient, setChosenPatient] = React.useState(-1)
    const [demoId, setDemoId] = React.useState(-1)

    const controller = React.useMemo(() => new AbortController(), [])
    const signal = controller.signal

    React.useEffect(() => {
        if (chosenPatient >= 0) setStatus(2)
    }, [chosenPatient])

    React.useEffect(() => {
        if (status === 1) {
            setDemoId(-1)
            getPatientsList(signal)
                .then(response => setBoolList(response.data.patients))
                .catch(error => {if (error.response.status === 404) setUnauthorised(true)})
        } else if (status === 2) runPatient(chosenPatient, signal)
            .then(response => {
                setStatus(3)
                setDemoId(response.data.id)
            })
            .catch(error => setStatus(4))
    }, [status])

    React.useEffect(() => {
        if (boolList && boolList.length > 0) {
            const result = []
            boolList.forEach((bool, index) => {
                if (bool && index < patients_list.length) result.push(patients_list[index])
            })
            setPatientList(result)
        }
    }, [boolList])

    if (status === 0) {
        return <div className='master-choices'>
            <MasterBtn onClick={() => setStatus(1)}>Run patient already uploaded</MasterBtn>
            <MasterBtn onClick={startUploadPatient}>Upload your own patient</MasterBtn>
        </div>
    } else if (status === 1) {
        return <div className='master-choices-patients'>
            {patientList
                ? patientList.length > 0
                    ? patientList.map((patient, keyPatient) => {
                        return <MasterPatientBtn key={keyPatient} onClick={() => setChosenPatient(patients_list.indexOf(patient))}>
                            {patient}
                        </MasterPatientBtn>
                    })
                    : <p style={{margin: '50px 0'}}>You have already tested all provided patients. <br/>
                        You can find the generated reports in the Home Page.</p>
                : unauthorised ? <p style={{margin: '50px 0'}}>You don't have permission to do that.</p> : <p>Loading</p>
            }
            <CancelRedBtn onClick={() => setStatus(0)}>BACK</CancelRedBtn>
        </div>
    } else if (status === 2 || status === 4) return <div style={{width: '65vw', margin: '100px auto'}}>
        <Diagnosis error={status === 4}/>
    </div>
    else if (status === 3 && demoId >= 0) return <Redirect to={{pathname: '/Review', state: {'patient_id': demoId}}}/>
    else return <p>WAITING status {status}-{demoId}</p>
}