import React from "react";
import {useLocation} from "react-router-dom";

export const ViewerHistory = props => {
    const state = useLocation().state
    const patient_id = state !== undefined ? state.id : null
    return <Viewer {...props} patient_id={patient_id}/>
}

const Viewer = ({patient_id, handleLogout}) => {
    return <div className='app-content'>
        <h1>VIEWER FOR PATIENT : {patient_id}</h1>
    </div>
}