import {Grid} from "@mui/material"
import React from "react"
import './NavBar.css'
import {LogoutBlueBtn, NavBarBtn} from "../Buttons/CustomButtons";
import {Link} from "react-router-dom";

const NavBarButton = ({link, label, onClick}) => {
    return <Grid item xs="auto" style={{display: 'flex'}}>
        <NavBarBtn onClick={onClick}><Link to={link} className='links'>{label}</Link></NavBarBtn>
    </Grid>
}

export const NavBar = ({win_size, username, handleLogout, is_surgeon}) => {
    const [click, setClick] = React.useState(false)
    const title_size = win_size === 'xsmall' ? '20px' : win_size === 'small' ? '26px' : '32px'

    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)

    return <Grid container className='navbar'>
        <Grid item xs="auto" style={{display: 'flex'}}>
            <h1 style={{margin: 'auto 10px', width: 'fit-content', 'fontSize': title_size, color: 'var(--dark-blue)'}}>
                Welcome {username}
            </h1>
        </Grid>
        <Grid item xs className={click ? 'nav-menu active' : 'nav-menu'}>
            <Grid container>
                <Grid item xs="auto" style={{display: 'flex'}}>
                    <NavBarButton link='/Home' label='Home' onClick={closeMobileMenu}/>
                </Grid>
                {!is_surgeon && <Grid item xs="auto" style={{display: 'flex'}}>
                    <NavBarButton link='/NewDiagnosis' label='New diagnostic' onClick={closeMobileMenu}/>
                </Grid>}
                <Grid item xs />
                <Grid item xs="auto" style={{display: 'flex'}}>
                    <LogoutBlueBtn onClick={handleLogout}>Logout</LogoutBlueBtn>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs="auto" className='small-menu' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </Grid>
    </Grid>
}