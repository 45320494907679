import {axiosInstance, axiosInstanceNoError} from "../http-common"

export const fetchUserData = async () => {
    return await axiosInstance.get(`/core/current_user/`)
}

export const loginService = async (data) => {
    return await fetch(`${process.env.REACT_APP_IP}/token-auth/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
}

export const createToken = async (data) => {
    const response = await axiosInstance.post(`${process.env.REACT_APP_IP}/token-auth/`, data);
    const { token, user } = response.data;
    if (token && user) {
        await newSession(token);
        sessionStorage.setItem('token', token);
    }
    return user
}

export const newSession = async (token) => {
    return await axiosInstance.put(`${process.env.REACT_APP_IP}/core/sessions/`, {'status': 'start'},
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${token}`
            }
        }
    )
}

export const updateSession = async () => {
    const token = sessionStorage.getItem('token');
    const response = await axiosInstance.put(`${process.env.REACT_APP_IP}/core/sessions/`, {'status': 'end'},
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${token}`
            }
        }
    )
    return response.data;
}