import {withStyles} from "@material-ui/core/styles";
import {Button} from "@mui/material";
import React from "react";

export const MasterBtn = withStyles({
    root: {
        width: '300px',
        height: '200px',
        margin: 'auto 10px',
        textDecoration: "none",
        backgroundColor: 'var(--medium-gold)',
        border : '2px solid var(--medium-gold)',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '2px solid var(--medium-gold)',
        },
        color: "black"
    },
    label: {
        textTransform: "capitalize"
    }
})(props => <Button {...props} />);

export const MasterPatientBtn = withStyles({
    root: {
        width: '250px',
        height: '50px',
        margin: '10px auto',
        textDecoration: "none",
        backgroundColor: 'var(--medium-gold)',
        border : '2px solid var(--medium-gold)',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '2px solid var(--medium-gold)',
        },
        color: "black"
    }
})(props => <Button {...props} />);