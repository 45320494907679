const MODS_TO_BE_FOUND = ['Sag T1', 'Sag T2', 'Ax T2']

export const ivd = ['T8/T9', 'T9/T10', 'T10/T11', 'T11/T12', 'T12/L1', 'L1/L2', 'L2/L3', 'L3/L4', 'L4/L5', 'L5/S1']
export const vb = ['T8', 'T9', 'T10', 'T11', 'T12', 'L1', 'L2', 'L3', 'L4', 'L5', 'S1']

export const error401 = () => {
    sessionStorage.removeItem('token')
    alert('Your session expired. Please, log in again.')
    window.location.reload()
}

export const checkForIssues = dict => {
    const values = Object.values(dict)
    const available = Object.keys(dict)
    const issues = []
    MODS_TO_BE_FOUND.forEach(mod => {
        if (values.filter(value => value === mod).length !== 1) issues.push(mod)
        else {
            available.splice(values.indexOf(mod),1)
            values.splice(values.indexOf(mod),1)
        }
    })
    return [issues, available]
}

/**
 *
 * @param chosen_mods - dictionnaire qui liste les choix de modalite de l'utilisateur
 *          ex: {'sag_t2_dixon_in' : 'Sag T2'}
 * @param issues - dictionnaire qui liste toutes les modalites et qu'on veut renvoyer au backend
 *          ex: {
 *                  'sag_t2_dixon_in': 'Sag T2',
 *                  'sag_t2_dixon_opp': 'Sag T2',
 *                  'sag_t2_dixon_w': 'Sag T2',
 *                  'sag_t2_dixon_f': 'Sag T2'
 *          }
 * @returns {{}} - dictionnaire a renvoyer au backend avec les modalites finales
 *          ex: {
 *                  'sag_t2_dixon_in': 'Sag T2',
 *                  'sag_t2_dixon_opp': 'sag_t2_dixon_opp',
 *                  'sag_t2_dixon_w': 'sag_t2_dixon_w',
 *                  'sag_t2_dixon_f': 'sag_t2_dixon_f'
 *          }
 */
export const getResolvedIssues = (chosen_mods, issues) => {
    const new_resolved_issues = {}
    Object.keys(issues).forEach(keyMod => {
        let default_value = issues[keyMod]
        let result = default_value
        if (chosen_mods[keyMod] === 'Not provided') {
            result = keyMod
        } else {
            if (keyMod === default_value && Object.keys(chosen_mods).find(elt => elt === keyMod) !== undefined) {
                //on avait au depart 'sag_t2_dixon_in' : 'sag_t2_dixon_in' et 'sag_t2_dixon_in' est dans chosen_mods
                result = chosen_mods[keyMod]
            } else {
                if (Object.values(chosen_mods).find(elt => elt === default_value) !== undefined && chosen_mods[keyMod] !== default_value) {
                    //on avait au depart 'sag_t2_dixon_in' : 'Sag T2' et 'Sag T2' est dans chosen_mods (cad qu'on ne savait pas quel Sag T2 choisir)
                    result = keyMod
                }
            }
        }
        new_resolved_issues[keyMod] = result
    })
    return new_resolved_issues
}