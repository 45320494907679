import {Grid} from "@material-ui/core";
import React from "react";
import DescriptionIcon from '@mui/icons-material/Description';

export const GridPatient = ({widths, color, patient_id, patient_name, aquisition_date, diagnostic_date, identity,
                                handleReview}) => {
    const aquis_date = aquisition_date === '2000-01-01' ? '' : aquisition_date
    return <Grid container style={{backgroundColor: color, cursor: 'default'}}>
        <Grid container>
            <Grid item xs={widths[0]} style={{display: 'flex'}}>
                <Grid container style={{margin: 'auto'}}>
                    <Grid item xs={2}><p style={{fontSize: '16px', color: 'var(--dark-blue)'}}>{patient_id}</p></Grid>
                    <Grid item xs={8}><p style={{fontSize: '16px', color: 'var(--dark-blue)'}}>{patient_name}</p></Grid>
                    <Grid item xs={2}><DescriptionIcon id="patient-pdf-icon" onClick={handleReview}/></Grid>
                </Grid>
            </Grid>
            <Grid item xs={widths[1]} style={{display: 'flex'}}>
                <Grid container style={{margin: 'auto'}}>
                    <Grid item xs={6}><p style={{fontSize: '16px', color: 'var(--dark-blue)'}}>{aquis_date}</p></Grid>
                    <Grid item xs={6}><p style={{fontSize: '16px', color: 'var(--dark-blue)'}}>{diagnostic_date}</p></Grid>
                </Grid>
            </Grid>
            {identity
                ? <Grid item xs={widths[2]} style={{display: 'flex'}}>
                    <Grid container style={{margin: 'auto'}}>
                        {['sex', 'age', 'height', 'weight'].map((label, keyLabel) => {
                            return <Grid key={keyLabel} item xs={3}>
                                <p style={{fontSize: '16px', color: 'var(--dark-blue)'}}>
                                {identity[label] !== -1 && identity[label] !== 'NA' ? identity[label] : ''}</p>
                            </Grid>
                        })}
                    </Grid>
                </Grid>
                : <Grid item xs={widths[2]}/>
            }
        </Grid>
    </Grid>
}