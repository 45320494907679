import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {Button} from "@mui/material";

export const NavBarBtn = withStyles({
    root: {
        margin: 'auto 10px',
        textDecoration: "none",
        backgroundColor: 'var(--medium-gold)',
        border : '2px solid var(--medium-gold)',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '2px solid var(--medium-gold)',
        },
        color: "black"
    },
    label: {
        textTransform: "capitalize"
    }
})(props => <Button {...props} />);

export const StartAnalysisBtn = withStyles({
    root: {
        margin: 'auto',
        backgroundColor: 'var(--medium-gold)',
        border : '2px solid var(--medium-gold)',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '2px solid var(--medium-gold)',
        },
        color: "black"
    },
    label: {
        textTransform: "capitalize"
    }
})(props => <Button {...props} />);

export const LogoutBlueBtn = withStyles({
    root: {
        margin: 'auto 10px',
        backgroundColor: 'var(--dark-blue)',
        color: 'white',
        width: 'fit-content',
        border : '2px solid var(--dark-blue)',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '2px solid var(--dark-blue)',
            color: 'var(--dark-blue)'
        },
    },
    label: {
        textTransform: "capitalize"
    }
})(props => <Button {...props} />);

export const NotProvidedBtn = withStyles({
    root: {
        margin: '10px auto',
        backgroundColor: 'var(--dark-blue)',
        color: 'white',
        width: 'fit-content',
        border : '2px solid var(--success-color)',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '2px solid var(--success-color)',
            color: 'var(--success-color)'
        },
    },
    label: {
        textTransform: "capitalize"
    }
})(props => <Button {...props} />);

export const StartDiagnosisBtn = withStyles({
    root: {
        margin: '10px auto',
        backgroundColor: 'var(--success-color)',
        color: 'white',
        width: 'fit-content',
        border : '2px solid var(--success-color)',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '2px solid var(--success-color)',
            color: 'var(--success-color)'
        },
    },
    label: {
        textTransform: "capitalize"
    }
})(props => <Button {...props} />);

export const CancelRedBtn = withStyles({
    root: {
        margin: '10px auto',
        backgroundColor: 'crimson',
        color: 'white',
        width: 'fit-content',
        border : '2px solid crimson',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '2px solid crimson',
            color: 'crimson'
        },
    },
    label: {
        textTransform: "capitalize"
    }
})(props => <Button {...props} />);