import {StartAnalysisBtn} from "../../../Buttons/CustomButtons";
import React from "react";

export const ImportDICOMFiles = ({handleFiles, ready_to_analysis, startAnalysis}) => {
    return <div className='content-import-import'>
        <h2>Import DICOM files</h2>
        <div className='warning-box'>
            <p>The demonstrator is not a final version of the product.</p>
            <p>The demonstrator is only compatible with <b>SagT1, SagT2, Sag FLAIR, Sag DIXON and AxT2</b>.</p>
            <p>Disc denegeration can only be evaluated if SagT2 (or Sag DIXON in) images are provided.</p>
            <p>Disc herniation can only be evaluated if both SagT2 (or Sag DIXON in) and AxT2 are provided.</p>
            <p>Endplate degeneration can only be evaluated if SagT1 (or Sag FLAIR) and SagT2 (or Sag DIXON in)
                images are uploaded.</p>
            <p>Results delivered might be inaccurate in case of artefacts, implants or fusion between
                vertebrae present on the images.</p>
            <p>The presence of orthopedic material might interfere with the results delivered by the solution.</p>
            <p>Results delivered can not be modified.</p>
        </div>
        <input
            id="my_file"
            type="file"
            directory=""
            webkitdirectory=""
            multiple
            accept="image/png, image/gif, image/jpeg"
            onInput={handleFiles}
        />
        {ready_to_analysis && <StartAnalysisBtn onClick={startAnalysis}>Start analysis</StartAnalysisBtn>}
    </div>
}