import React from 'react'
import {Alert, Stack} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import {ErrorComponent} from "./utils";

const MAX_PROGRESS = 90

export const Diagnosis = ({error}) => {
    const [progress, setProgress] = React.useState(0)

    React.useEffect(() => {
        const timer = setInterval(async () => {
            if (progress >= 0 && !error) setProgress(prevProgress => (prevProgress + 1))
            if (progress === MAX_PROGRESS) setProgress(-1)
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [progress, setProgress, error]);

    return <>
        <div className='progress-box'>
            <p>Diagnosis {error ?<i className="fas fa-exclamation-triangle"/> : '...' }</p>
            <Stack sx={{ color: 'var(--dark-blue)'}}>
                <LinearProgress
                    color="inherit"
                    variant="determinate"
                    value={(progress*100)/MAX_PROGRESS >= 0 && (progress*100)/MAX_PROGRESS <= 100  ? (progress*100)/MAX_PROGRESS : 100}
                />
            </Stack>
        </div>
        {error
            ? <ErrorComponent error={error} />
            : progress < 0 && <div style={{width: '500px', margin: '0 auto', display: 'flex', flexDirection: 'column'}}>
            <Alert severity="warning">The diagnosis takes longer than expected, just a few more seconds...</Alert>
        </div>
        }
    </>
}