import {withStyles} from "@material-ui/core/styles";
import {Button} from "@mui/material";
import React from "react";

export const MissingSerieBtn = withStyles({
    root: {
        width: '120px',
        height: '35px',
        margin: 'auto',
        textDecoration: "none",
        backgroundColor: 'white',
        border : '2px solid var(--error-color)',
        borderRadius: '5px',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            color: 'var(--error-color)'
        },
        color: "black"
    }
})(props => <Button {...props} />);

export const NotMissingSerieBtn = withStyles({
    root: {
        width: '120px',
        height: '35px',
        margin: 'auto',
        textDecoration: "none",
        backgroundColor: 'white',
        border : '2px solid var(--success-color)',
        borderRadius: '5px',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            color: 'var(--success-color)'
        },
        color: "black"
    }
})(props => <Button {...props} />);

export const NextIssueBtn = withStyles({
    root: {
        width: '120px',
        height: '35px',
        margin: 'auto',
        textDecoration: "none",
        backgroundColor: 'var(--dark-blue)',
        color: "white",
        border : '2px solid var(--dark-blue)',
        borderRadius: '5px',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            color: 'var(--dark-blue)'
        }
    }
})(props => <Button {...props} />);