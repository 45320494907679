import React from 'react'
import './UploadImages.css'
import {newDemo, postFiles, postSortingFiles} from "../../../services/ImportService";
import {error401} from "../../../utils";
import {Button} from "@mui/material";
import {Diagnosis} from "../../Diagnosis/Diagnosis";
import {getIssues} from "../../../services/IssueService";
import {ImportDICOMFiles} from "./Steps/ImportDICOMFiles";
import {DetectionProgressBar, SortingProgressBar, UploadProgressBar} from "./Steps/ProgressBars";

const BATCH_SIZE = 20

export default class UploadImages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: undefined,

            nb_batchs: 0,
            progress_dict: {},
            upload_progress: 0,
        }

        this.controller = new AbortController();
        this.signal = this.controller.signal;
    }

    componentWillUnmount() {
        this.controller.abort()
    }

    handleProgress = (batch_id, new_progress) => {
        const new_progress_dict = {...this.state.progress_dict, [batch_id]: new_progress}
        let total = 0
        Object.values(new_progress_dict).forEach(prog => total += prog)
        this.setState({
            upload_progress: total/this.state.nb_batchs,
            progress_dict: new_progress_dict
        })
    }

    startGetIssues = () => {
        /**
         * Transition between statuses 5 and 6:
         *      => sends a request to the backend to retrieve the list of series found among those provided
         *
         *      => found_issues is a boolean
         *      => issues is a dictionary
         *          ex: {'stir_coro_de_seze': 'stir_coro_de_seze', 't1_tse_sag': 'Sag T1', 't2_fdixon_sag_f': 'Sag T2', 't2_fdixon_sag_in': 'Sag T2'}
         *
         */

        const start = Date.now();
        getIssues(this.props.demo_id, this.signal).then(response => {
            if (response.status && response.status === 200) {
                this.props.setStatus(6)
                this.props.setIssuesTime((Date.now() - start) / 1000)
                //if (response.data.found_issues) this.props.checkForIssues(response.data.issues)
                this.props.checkForIssues(response.data.issues)
            }
        }).catch(error => {
            if (error.response && error.response.status === 401) error401()
            else this.props.setStatusError()
        })
    }

    startSortingFiles = () => {
        /**
         * Transition between statuses 4 and 5:
         *      => sends a request to the backend to move the images into the right folders
         */

        const start = Date.now();
        postSortingFiles(this.props.demo_id, this.signal).then(response => {
            if (response.status && response.status === 200) {
                this.props.setStatus(9)
                this.props.setSortingTime((Date.now() - start) / 1000)
                this.props.startDiagnosis()
            }
        }).catch(error => {
            if (error.response && error.response.status === 401) error401()
            else this.props.setStatusError()
        })
    }

    postBatch = (files, batch_id) => {
        return postFiles(this.props.demo_id, files, new_progress => this.handleProgress(batch_id, new_progress), this.signal)
    }

    uploadBatchs = (batchs) => {
        const promises = batchs.map((batch, keyBatch) => this.postBatch(batch, keyBatch))
        return Promise.all(promises)
    }

    getBatchs = (previous, nb_batchs) => {
        this.setState({nb_batchs: nb_batchs})
        const result = []
        for (let i=0; i < nb_batchs; i++) {
            const nb_images = i === nb_batchs - 1 ? previous.length%BATCH_SIZE : BATCH_SIZE
            result.push(previous.slice(i*BATCH_SIZE, i*BATCH_SIZE + nb_images))
        }
        return result
    }

    startFilesUpload = () => {
        /**
         * Transition between statuses 3 and 4:
         *      => cuts smaller batch files that are then sent backend
         *
         * @type {number}
         */
        const start = Date.now()
        const batchs = this.getBatchs(this.state.files, Math.trunc(this.state.files.length/BATCH_SIZE)+1)
        this.uploadBatchs(batchs)
            .then(() => {
                this.props.setStatus(4)
                this.props.setTimeUploadTime((Date.now() - start) / 1000)
                this.startSortingFiles()
            })
    }

    startAnalysis = () => {
        /**
         * Transition between statuses 2 and 3:
         *      => sends a request to the backend to create or retrieve a "demonstrator" instance that will then be
         *      associated with the images and pdf report
         */
        newDemo(this.signal).then(response => {
            if (response.status && response.status === 201) {
                this.props.setId(response.data.message)
                this.props.setStatus(3)
                this.startFilesUpload()
            }
        }).catch(error => {
            if (error.response && error.response.status === 401) error401()
            else this.props.setStatusError()
        })
    }

    handleFiles = (new_files) => {
        /**
         * Pre-sorts the files found in the chosen folder to try to find only images
         *
         * @param FileList new_files
         *
         */
        const result = []
        for (let i=0; i<new_files.length; i++) {
            let type = new_files[i]["type"]
            if (type === "" || type.startsWith("image/") || type === "application/dicom") result.push(new_files[i])
            else console.log("ignored files "+new_files[i]["webkitRelativePath"])
        }
        this.setState({files: result})
    }

    render() {
        return <div className='content-import'>
            {this.props.status < 3
                ? <ImportDICOMFiles
                    handleFiles={e => this.handleFiles(e.target.files)}
                    ready_to_analysis={this.state.files && Object.keys(this.state.files).length > 0}
                    startAnalysis={this.startAnalysis}
                />
                : <div className='content-import-sorting'>
                    {this.props.status >= 3 && <UploadProgressBar
                        success={this.props.status >= 4}
                        progress={this.state.upload_progress}
                    />}
                    {this.props.status >= 4 && <SortingProgressBar
                        success={this.props.status >= 5}
                    />}
                    {this.props.status >= 5 && <DetectionProgressBar
                        finished={this.props.status >= 6}
                        success={!this.props.missingSeries || this.props.status >= 8}
                    />}
                    {this.props.status === 6
                        ? this.props.missingSeries && <Button id="resolve-issues" onClick={this.props.resolveIssues}>RESOLVE ISSUES</Button>
                        : this.props.status >= 8 && <Diagnosis error={this.props.diagnosisError !== -1}/>
                    }
                </div>
            }
        </div>
    }
}