import React from "react";
import "./Issues.css"
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid
} from "@mui/material";
import {MissingSerieBtn, NextIssueBtn, NotMissingSerieBtn} from "../../Buttons/IssuesButtons";


const series_to_check = ['Sag T1', 'Sag T2', 'Ax T2']


const ChoseSeries = ({issue, folders, handleChange}) => {
    const [chosenList, setChosenList] = React.useState([])

    const handleSelectSerie = (serie) => {
        if (chosenList.some(elt => elt === serie)) setChosenList(chosenList.filter(elt => elt !== serie))
        else {
            const new_list = chosenList.slice()
            new_list.push(serie)
            setChosenList(new_list)
        }
    }

    const handleNextIssue = () => {
        if (chosenList.length === 0) {
            alert(`You can not proceed if ${issue} is not selected`)
        } else handleChange(chosenList)
    }

    React.useEffect(() => {
        setChosenList([])
    }, [issue])

    return <div className='checkboxes'>
        <FormGroup>
            {folders.map((folder, key) => {
                return <FormControlLabel
                    key={key}
                    value={folder}
                    style={{margin: 0}}
                    control={<Checkbox
                        size="small"
                        sx={{
                            color: 'grey',
                            '&.Mui-checked': {
                                color: 'var(--dark-blue)',
                            },
                        }}
                        checked={chosenList.some(elt => elt === folder)}
                        onChange={e => handleSelectSerie(e.target.value)}
                    />}
                    label={folder}
                />
            })}
        </FormGroup>
        <NextIssueBtn onClick={handleNextIssue}>NEXT ISSUE</NextIssueBtn>
    </div>
}

const Issues = ({available, missingSeries, startIssuesResolved}) => {
    const [current, setCurrent] = React.useState(0)

    const [seriesList, setSeriesList] = React.useState(available.slice()) //series parmi lesquelles on peut choisir
    const [results, setResults] = React.useState({})

    React.useEffect(() => {
        if (current === missingSeries.length) startIssuesResolved(results)
    }, [current, missingSeries])

    const handleChange = (issue, chosenList) => {
        // issue: 'SagT1'  chosenList: ['coro_stir'] ou ['L2-L3', 'L3-L4', 'L4-L5']
        const new_results = {...results}
        chosenList.forEach(serie => {
            new_results[serie] = issue
            setSeriesList(seriesList.filter(available => available !== serie))
        })
        setResults(new_results)
        setCurrent(current+1)
    }

    return <div className='content-issues'>
        <div className='content-issues-up'>
            <p>We are having problem detecting some series among the provided folders:</p>
            <Grid container style={{width: '40vw', margin: 'auto'}}>
                {series_to_check.map((serie, keySerie) => {
                    const missing = missingSeries.some(elt => elt === serie)
                    return <Grid item xs={12/series_to_check.length} key={keySerie} style={{display: 'flex'}}>
                        {missing
                            ? <MissingSerieBtn>{serie} <i className="fas fa-exclamation-triangle"/></MissingSerieBtn>
                            : <NotMissingSerieBtn>{serie} <i className="far fa-check-circle"/></NotMissingSerieBtn>
                        }
                    </Grid>
                })}
            </Grid>
        </div>
        <div className='content-issues-columns'>
            <div className='content-issues-columns-issues'>
                <div className='content-issues-columns-issues-title'>
                    <p style={{fontSize: '20px', color: 'var(--dark-blue)'}}>Please select the correct serie for</p>
                    <p style={{fontSize: '18px', color: 'var(--error-color)', margin: '10px auto'}}>{missingSeries[current]}</p>
                </div>
                <ChoseSeries
                    issue={missingSeries[current]}
                    folders={seriesList}
                    handleChange={chosenList => handleChange(missingSeries[current], chosenList)}
                />
            </div>
        </div>
    </div>
}
export default Issues