import {alpha, FormControlLabel, Grid, Slider, styled, Switch} from "@mui/material";
import React from "react";

const xsmall_slider_marks = Array.from({ length: 10 }, (_, index) => ({ value: (index + 1) * 5, label: (index + 1) * 5 }));
const small_slider_marks = Array.from({ length: 19 }, (_, index) => ({ value: (index + 1) * 10, label: (index + 1) * 10 }));
const slider_marks = Array.from({ length: 20 }, (_, index) => ({ value: (index + 1) * 20, label: (index + 1) * 20 }));

const SliceSlider = styled(Slider)({
    width: '95%',
    margin: '5px auto 15px',
    height: 3,
    color: 'white',
    "& .MuiSlider-markLabel": {
        color: "white",
        fontSize: 9, // Taille de la police des légendes
        margin: 0
    },
    "& .MuiSlider-thumb": {
        height: 12,
        width: 12,
        backgroundColor: 'white',
        border: "2px solid currentColor",
        "&:hover, &:focus, &.Mui-active, &.Mui-focusVisible": {
            boxShadow: '0 0 0 6px rgba(19, 55, 90, 0.2)',
            cursor: "pointer"
        },
    },

});

const WhiteSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#fff',
        '&:hover': {
            backgroundColor: alpha('#fff', theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#fff',
    },
}));

export const SagAxWindow = ({both_mod = true, sag_ax, mod, setMod, img, loading, img_clean, curr_slice,
                              nb_slices, onWheelFunction, updateSlice}) => {
    const [displayOverlay, setDisplayOverlay] = React.useState(true)

    return <div className='pdf-window' onWheel={onWheelFunction}>
        <Grid container className='pdf-window-text-box'>
            <Grid item xs={5} style={{display: 'flex'}}>
                {both_mod
                    ? <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={4}><p className='pdf-window-text' style={{color: 'white'}}>{sag_ax} T1</p></Grid>
                        <Grid item xs={4} style={{display: 'flex'}}>
                            <FormControlLabel
                                style={{color: 'white', margin: 'auto'}}
                                control={<WhiteSwitch checked={mod === 2} onChange={() => setMod(mod === 1 ? 2 : 1)} />}
                            />
                        </Grid>
                        <Grid item xs={4}><p className='pdf-window-text' style={{color: 'white'}}>{sag_ax} T2</p></Grid>
                    </Grid>
                    : <p className='pdf-window-text' style={{color: 'white'}}>{sag_ax} T{mod}</p>
                }
            </Grid>
            <Grid item xs={7} style={{display: 'flex'}}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={4} style={{display: 'flex'}}>
                        <p className='pdf-window-text'>Slice {curr_slice}/{nb_slices}</p>
                    </Grid>
                    <Grid item xs={8} style={{display: 'flex'}}>
                        <FormControlLabel
                            style={{color: 'white', margin: 'auto'}}
                            control={<WhiteSwitch checked={displayOverlay} onChange={() => setDisplayOverlay(!displayOverlay)}/>}
                            label="Display overlay"
                            labelPlacement="end"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container className='pdf-window-slider'>
            <SliceSlider
                min={1}
                max={nb_slices}
                marks={nb_slices <= 50 ? xsmall_slider_marks : nb_slices <= 180 ? small_slider_marks : slider_marks}
                defaultValue={0}
                value={curr_slice}
                onChange={(e, value) => updateSlice(value)}
            />
        </Grid>
        <div className='pdf-window-img-box'>
            {loading
                ? <p>Loading...</p>
                : <img className='pdf-windows-img' src={displayOverlay ? img : img_clean} alt='img not found'/>
            }
        </div>
    </div>
}