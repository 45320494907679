import {axiosInstance} from "../http-common";

export const getIssues = async (id, signal) => {
    const fd = new FormData()
    fd.append('id', id)
    return await axiosInstance.post(`/core/upload/getIssues/`, fd, {
        signal: signal,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const putIssuesResolved = async (issues, id, signal) => {
    const data = {
        'demo_id': id,
        'issues': issues
    }
    return await axiosInstance.put(`/core/upload/issuesResolved/`, data, {signal: signal})
}