import {getOverlayImg} from "../../../services/ReviewService";
import React from "react";
import {SagAxWindow} from "./Windows";
import './PDFViewer.css'

export default class PDFViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sag_mods: [1, 2],
            current_sag_mod: 1,
            sag_slice: 1,
            sag_imgs: {},
            sag_imgs_clean: {},
            sag_loading: true,

            ax_mods: [2],
            current_ax_mod: 2,
            ax_slice: 1,
            ax_imgs: {},
            ax_imgs_clean: {},
            ax_loading: true
        }
        this.controller = new AbortController()
        this.signal = this.controller.signal
    }

    getSagSlices = () => {
        this.uploadSagAx("Sag", this.state.sag_mods, true).then(r => this.setState({sag_loading: false}))
        this.uploadSagAx("Sag", this.state.sag_mods, false).then(r => this.setState({sag_loading: false}))
    }
    getAxSlices = () => {
        this.uploadSagAx("Ax", this.state.ax_mods, true).then(r => this.setState({ax_loading: false}))
        this.uploadSagAx("Ax", this.state.ax_mods, false).then(r => this.setState({ax_loading: false}))
    }

    componentDidMount() {
        this.getSagSlices()
        this.getAxSlices()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.nb_slices_ax !== prevProps.nb_slices_ax) this.setState({ax_loading: true})
        if (this.state.ax_loading !== prevState.ax_loading && this.state.ax_loading) this.getAxSlices()
        if (this.props.nb_slices_sag !== prevProps.nb_slices_sag) this.setState({sag_loading: true})
        if (this.state.sag_loading !== prevState.sag_loading && this.state.sag_loading) this.getSagSlices()
    }

    componentWillUnmount() {
        this.controller.abort()
    }

    uploadSlice = async (sag_ax, mod, slice_id, clean) => {
        await getOverlayImg(this.props.patient_id, sag_ax, mod, slice_id, clean, this.signal)
            .then(blob => {
                if (sag_ax === 'Sag') {
                    if (clean) {
                        const current = {...this.state.sag_imgs_clean[mod], [slice_id]: blob}
                        const blobs = {...this.state.sag_imgs_clean, [mod]: current}
                        this.setState({sag_imgs_clean: blobs})
                    } else {
                        const current = {...this.state.sag_imgs[mod], [slice_id]: blob}
                        const blobs = {...this.state.sag_imgs, [mod]: current}
                        this.setState({sag_imgs: blobs})
                    }
                } else if (sag_ax === 'Ax') {
                    if (clean) {
                        const current = {...this.state.ax_imgs_clean[mod], [slice_id]: blob}
                        const blobs = {...this.state.ax_imgs_clean, [mod]: current}
                        this.setState({ax_imgs_clean: blobs})
                    } else {
                        const current = {...this.state.ax_imgs[mod], [slice_id]: blob}
                        const blobs = {...this.state.ax_imgs, [mod]: current}
                        this.setState({ax_imgs: blobs})
                    }
                }

            })
    }
    uploadSerie = async (sag_ax, mod, clean) => {
        const nb_slices = sag_ax === 'Sag' ? this.props.nb_slices_sag : this.props.nb_slices_ax
        const sliceUploads = [...Array(nb_slices).keys()]
            .map(slice => this.uploadSlice(sag_ax, mod, slice, clean))
        await Promise.all(sliceUploads)
    }
    uploadSagAx = async (sag_ax, mods, clean) => {
        const upload = mods.map(mod => this.uploadSerie(sag_ax, mod, clean))
        await Promise.all(upload).catch(() => this.controller.abort())
    }

    handleSagScroll = e => {
        if (e.deltaY<0) this.updateSagSlice(this.state.sag_slice+1)
        else if (e.deltaY>0) this.updateSagSlice(this.state.sag_slice-1)
    }
    updateSagSlice = (new_slice) => {
        if (new_slice>0 && new_slice<this.props.nb_slices_sag+1) this.setState({sag_slice: new_slice})
    }
    handleAxScroll = e => {
        if (e.deltaY<0) this.updateAxSlice(this.state.ax_slice+1)
        else if (e.deltaY>0) this.updateAxSlice(this.state.ax_slice-1)
    }
    updateAxSlice = (new_slice) => {
        if (new_slice>0 && new_slice<this.props.nb_slices_ax+1) this.setState({ax_slice: new_slice})
    }

    render() {
        const sag_img = this.state.sag_imgs !== null && this.state.sag_imgs[this.state.current_sag_mod] !== undefined
            ? this.state.sag_imgs[this.state.current_sag_mod][this.state.sag_slice-1]
            : null
        const sag_img_clean = this.state.sag_imgs_clean !== null && this.state.sag_imgs_clean[this.state.current_sag_mod] !== undefined
            ? this.state.sag_imgs_clean[this.state.current_sag_mod][this.state.sag_slice-1]
            : null
        const ax_img = this.state.ax_imgs !== null && this.state.ax_imgs[this.state.current_ax_mod] !== undefined
            ? this.state.ax_imgs[this.state.current_ax_mod][this.state.ax_slice-1]
            : null
        const ax_img_clean = this.state.ax_imgs_clean !== null && this.state.ax_imgs_clean[this.state.current_ax_mod] !== undefined
            ? this.state.ax_imgs_clean[this.state.current_ax_mod][this.state.ax_slice-1]
            : null
        return <div className='pdf-page-viewer'>
            <div className='pdf-windows'>
                <SagAxWindow
                    mod={this.state.current_sag_mod}
                    loading={this.state.sag_loading}
                    both_mod={true}
                    sag_ax={'Sag'}
                    setMod={new_mod => this.setState({current_sag_mod: new_mod})}
                    img={sag_img}
                    img_clean={sag_img_clean}
                    curr_slice={this.state.sag_slice}
                    nb_slices={this.props.nb_slices_sag}
                    onWheelFunction={this.handleSagScroll}
                    updateSlice={this.updateSagSlice}
                />
                <SagAxWindow
                    mod={this.state.current_ax_mod}
                    loading={this.state.ax_loading}
                    both_mod={false}
                    sag_ax={'Ax'}
                    img={ax_img}
                    img_clean={ax_img_clean}
                    curr_slice={this.state.ax_slice}
                    nb_slices={this.props.nb_slices_ax}
                    onWheelFunction={this.handleAxScroll}
                    updateSlice={this.updateAxSlice}
                />
            </div>
        </div>
    }
}