// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
    max-height: calc(100vh - var(--home-bar) - 150px);
    overflow: auto;
}

.links {
    text-decoration: none;
    cursor: pointer;
    color: var(--dark-blue);
}

#home-table {
    background-color: var(--light-gold);
    margin-top: 25px;
}

.home-table-titles {
    padding: 5px 0;
    margin: 5px 0;
    background-color: var(--dark-blue);
    color: white;
}

.home-patho-text {
    font-size: 13px;
    color: var(--dark-blue);
}

#add-images-patient {
    margin: 15px auto;
    background-color: var(--medium-gold);
    border : 2px solid var(--medium-gold);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
}

#add-images-patient:hover {
    background-color: white;
    border : 2px solid var(--medium-gold);
}

#icon-down, #icon-up {
    cursor: pointer;
}

#add-images-icon, #patient-pdf-icon {
    display: flex;
    margin: auto;
    color: var(--dark-blue);
    cursor: pointer;
    max-height: 24px;
}

#add-images-icon:hover, #patient-pdf-icon:hover {
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,iDAAiD;IACjD,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,mCAAmC;IACnC,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,kCAAkC;IAClC,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,oCAAoC;IACpC,qCAAqC;IACrC,yCAAyC;AAC7C;;AAEA;IACI,uBAAuB;IACvB,qCAAqC;AACzC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".home-container {\n    max-height: calc(100vh - var(--home-bar) - 150px);\n    overflow: auto;\n}\n\n.links {\n    text-decoration: none;\n    cursor: pointer;\n    color: var(--dark-blue);\n}\n\n#home-table {\n    background-color: var(--light-gold);\n    margin-top: 25px;\n}\n\n.home-table-titles {\n    padding: 5px 0;\n    margin: 5px 0;\n    background-color: var(--dark-blue);\n    color: white;\n}\n\n.home-patho-text {\n    font-size: 13px;\n    color: var(--dark-blue);\n}\n\n#add-images-patient {\n    margin: 15px auto;\n    background-color: var(--medium-gold);\n    border : 2px solid var(--medium-gold);\n    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);\n}\n\n#add-images-patient:hover {\n    background-color: white;\n    border : 2px solid var(--medium-gold);\n}\n\n#icon-down, #icon-up {\n    cursor: pointer;\n}\n\n#add-images-icon, #patient-pdf-icon {\n    display: flex;\n    margin: auto;\n    color: var(--dark-blue);\n    cursor: pointer;\n    max-height: 24px;\n}\n\n#add-images-icon:hover, #patient-pdf-icon:hover {\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
