// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    height: var(--home-bar);
    width: 100vw;
}

.nav-menu {
}

.small-menu {
    display: none;
}
.fa-times, .fa-bars {
    display: block;
    font-size: 2rem;
}

@media screen and (max-width: 511px) {
    .nav-menu {
        z-index: 10;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        width: 100vw;
        height: var(--app-content);
        position: absolute;
        top: calc(var(--home-bar) + 2px);
        left: -100vh;
        transition: all 0.5s ease;
    }
    .nav-menu.active {
        background: var(--gold);
        left: 0;
        transition: all 0.5s ease;
    }
    .small-menu {
        height: var(--home-bar);
        width: 10vw;
        display: block;
        cursor: pointer;
        background-color: crimson;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/NavBar/NavBar.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;AAChB;;AAEA;AACA;;AAEA;IACI,aAAa;AACjB;AACA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,8BAA8B;QAC9B,yBAAyB;QACzB,YAAY;QACZ,0BAA0B;QAC1B,kBAAkB;QAClB,gCAAgC;QAChC,YAAY;QACZ,yBAAyB;IAC7B;IACA;QACI,uBAAuB;QACvB,OAAO;QACP,yBAAyB;IAC7B;IACA;QACI,uBAAuB;QACvB,WAAW;QACX,cAAc;QACd,eAAe;QACf,yBAAyB;IAC7B;AACJ","sourcesContent":[".navbar {\n    height: var(--home-bar);\n    width: 100vw;\n}\n\n.nav-menu {\n}\n\n.small-menu {\n    display: none;\n}\n.fa-times, .fa-bars {\n    display: block;\n    font-size: 2rem;\n}\n\n@media screen and (max-width: 511px) {\n    .nav-menu {\n        z-index: 10;\n        display: flex;\n        flex-direction: column-reverse;\n        justify-content: flex-end;\n        width: 100vw;\n        height: var(--app-content);\n        position: absolute;\n        top: calc(var(--home-bar) + 2px);\n        left: -100vh;\n        transition: all 0.5s ease;\n    }\n    .nav-menu.active {\n        background: var(--gold);\n        left: 0;\n        transition: all 0.5s ease;\n    }\n    .small-menu {\n        height: var(--home-bar);\n        width: 10vw;\n        display: block;\n        cursor: pointer;\n        background-color: crimson;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
