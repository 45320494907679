import React from "react";
import './Footer.css'
import {Grid} from "@mui/material";

export const Footer = () => {
    return <Grid container className='footer'>
        <Grid item xs={1}/>
        <Grid item xs={10}>
            <p className='footer-text'>Use of this part of the Caerus Medical website is strictly restricted to care givers working with Caerus Medical.</p>
        </Grid>
        <Grid item xs={1}><p className='footer-text' style={{textAlign: 'end', marginRight: '5px'}}>V pre-4.0.5.4</p></Grid>
    </Grid>
}