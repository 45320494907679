import {axiosInstance} from "../http-common";

export const newDemo = async (signal) => {
    return await axiosInstance.put(`/core/upload/newDemo/`, {signal: signal})
}

export const postFiles = async (id, files, setProgress, signal) => {
    const fd = new FormData()
    fd.append('demo_id', id)
    files.forEach((file, keyFile) => {
        fd.append('file_'+keyFile, file)
    })
    return await axiosInstance.post(`/core/upload/postFiles/`, fd,{
        signal: signal,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function( progressEvent ) {
            setProgress(( progressEvent.loaded / progressEvent.total ) * 100 )
        }
    })
}

export const postSortingFiles = async (id, signal) => {
    const fd = new FormData()
    fd.append('demo_id', id)
    return await axiosInstance.post(`/core/upload/sortingFiles/`, fd, {
        signal: signal,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const getDiagnosis = async (id, upload_time, sorting_time, issues_time, signal) => {
    const fd = new FormData()
    fd.append('id', id)
    fd.append('upload_time', upload_time)
    fd.append('sorting_time', sorting_time)
    fd.append('issues_time', issues_time)
    return await axiosInstance.post(`/core/upload/diagnosis/`, fd, {
        signal: signal,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const getImageBlob = async (demo_id, serie_id, slice_id, signal) => {
    //for viewer
    return await fetch(`${process.env.REACT_APP_IP}/core/images/${demo_id}/${serie_id}/${slice_id}/`, {
        method: 'GET',
        headers: {Authorization: `JWT ${sessionStorage.getItem('token')}`},
        signal
    })
        .then(response => {
            if (response.status === 401) {
                sessionStorage.removeItem('token')
                alert('Your session expired.')
                window.location.reload()
            } else if (response.status === 404) return null
            else return response.blob()
        }).then(my_blob => {
            if (my_blob instanceof Blob) return URL.createObjectURL(my_blob)
            else return null
        })
}