import axios from "axios";

export const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_IP}/`,
    headers: {
        Authorization: `JWT ${sessionStorage.getItem('token')}`,
        "Content-type": "application/json"
    }
});

export const axiosInstanceNoError = axios.create({
    baseURL: `${process.env.REACT_APP_IP}/`,
    headers: {
        Authorization: `JWT ${sessionStorage.getItem('token')}`,
        "Content-type": "application/json"
    }
});

/*axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            sessionStorage.removeItem('token')
            alert('Your session expired.')
            window.location.reload()
        }
    }
);*/