// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.master-choices {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.master-choices-patients {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/components/NewDiagnosis/Master.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,2BAA2B;AAC/B","sourcesContent":[".master-choices {\n    width: 100vw;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-evenly;\n}\n\n.master-choices-patients {\n    width: 100vw;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
