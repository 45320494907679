import React from "react";
import {DownloadPDFButton, NextBackPDFButton} from "../Buttons/ReviewButtons";
import './PDFPage.css'
import PDFViewer from "../OLD/PDFViewer/PDFViewer";
import {getDemoPDF, getNbSlices} from "../../services/ReviewService";
import {Document, Page} from "react-pdf";

import { pdfjs } from 'react-pdf';
import ReactToPrint from "react-to-print";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.js`;


export default class PDFPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //sag_t1_slices: 0,
            sag_t2_slices: 0,
            ax_t2_slices: 0,

            pdf: null,
            pdf_height: 0,
            pdf_width: 0,

            page: 1,
            nb_pages: null
        }

        this.controller = new AbortController()
        this.signal = this.controller.signal
    }

    updateNbSlices = () => {
        getNbSlices(this.props.data.id, this.signal).then(data => this.setState({
            //sag_t1_slices: data.data['Sag T1'],
            sag_t2_slices: data.data['Sag T2'],
            ax_t2_slices: data.data['Ax T2']
        }))
    }

    componentDidMount() {
        this.handleResize()
        window.addEventListener("resize", this.handleResize)
        getDemoPDF(this.props.data.id, this.signal).then(response => this.setState({pdf: response}))
        this.updateNbSlices()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.data.id !== prevProps.data.id) this.updateNbSlices()
    }

    componentWillUnmount() {
        this.controller.abort()
        window.removeEventListener("resize", this.handleResize)
    }

    handleResize = () => {
        const pdf = document.getElementById("pdf-elt")
        this.setState({
            pdf_height: pdf ? pdf.offsetHeight : window.innerHeight*0.8,
            pdf_width: pdf ? pdf.offsetWidth-6 : 0
        })
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        if (this.state.nb_pages === null) this.setState({ nb_pages: numPages })
    }

    render() {
        return <div className='pdf-page'>
            <PDFViewer
                patient_id={this.props.data.id}
                nb_slices_sag={this.state.sag_t2_slices} //Math.max(this.state.sag_t1_slices, this.state.sag_t2_slices)
                nb_slices_ax={this.state.ax_t2_slices}
            />
            <div className='pdf-page-pdf'>
                <div id={'pdf-elt'} className='pdf-constructor-block'>
                    <Document file={{url: this.state.pdf}} onLoadSuccess={this.onDocumentLoadSuccess}>
                        <Page height={this.state.pdf_height} width={this.state.pdf_width} pageNumber={this.state.page} />
                    </Document>
                    <div id='to-be-printed' ref={el => (this.componentRef = el)}>
                        <Document file={{url: this.state.pdf}}>
                            {Array.from({length: this.state.nb_pages}, (_, index) =>
                                <Page key={index} width={1183} pageNumber={index+1}/>
                            )}
                        </Document>
                    </div>
                </div>
                <div className='pdf-page-nav'>
                    <div className='pdf-page-nav-page'>
                        <NextBackPDFButton
                            disabled={this.state.page === 1}
                            onClick={() => this.setState({page: this.state.page-1})}
                        >BACK</NextBackPDFButton>
                        <p style={{margin: 'auto 0'}}>Page {this.state.page}/{this.state.nb_pages}</p>
                        <NextBackPDFButton
                            disabled={this.state.page === this.state.nb_pages}
                            onClick={() => this.setState({page: this.state.page+1})}
                        >NEXT</NextBackPDFButton>
                    </div>
                    <div className='pdf-page-nav-download'>
                        {this.state.nb_pages !== null && <ReactToPrint
                            trigger={() => <DownloadPDFButton>
                                DOWNLOAD PDF
                            </DownloadPDFButton>}
                            content={() => this.componentRef}
                            pageStyle={`@page { size: auto; margin: 1mm; }`}
                        />}
                    </div>
                </div>
            </div>
        </div>
    }
}