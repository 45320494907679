import React, {Suspense} from 'react'
import './App.css'
import Home from "./components/Home/Home"
import Master from "./components/NewDiagnosis/Master"
import LoginPage from './components/LoginPage/LoginPage'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import {fetchUserData, loginService, newSession} from './services/UserService'
import ProtectedRoute from "./ProtectedRoute"
import {Footer} from "./components/Footer/Footer"
import {ReviewHistory} from "./components/Review/Review"
import {ViewerHistory} from "./components/Viewer/Viewer";
import {NavBar} from "./components/NavBar/NavBar";
import SessionTimer from "./SessionTimer";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logged_in: !!sessionStorage.getItem('token'),
            data: {
                first_name: '',
                last_name: '',
                is_surgeon: true,
                id: -1,
            },
            loading: false,
            fetch_error: false,
            other_session_detected: false,
            wrong_credentials: false,
            password_missing: false,
            username_missing: false
        }
    }

    componentDidMount() {
        if (this.state.logged_in && this.state.data.first_name === '') {
            fetchUserData()
                .then(json => {
                    if (json.data.id) {
                        this.setState({
                            logged_in: true,
                            data: {
                                first_name: json.data.first_name,
                                last_name: json.data.last_name,
                                is_surgeon: !json.data.is_superuser,
                                id: json.data.id
                            },
                            wrong_credentials: false,
                            password_missing: false,
                            username_missing: false
                        })
                    } else this.logout()
                })
                .catch(() => this.logout())

        } else this.setState({})
    }

    handleLogin = (e, data) => {
        e.preventDefault();
        this.setState({loading: true})
        if (data.username && data.password) {
            loginService(data)
                .then(json => {
                    this.setState({loading: false})
                    if (json.token && json.user) return json
                    else {
                        this.setState({
                            other_session_detected: false,
                            wrong_credentials: true,
                            password_missing: false,
                            username_missing: false
                        })
                        return null
                    }
                })
                .then(json => {
                    if (json) {
                        newSession(json.token).then(response => {
                            if (response.status === 201) {
                                sessionStorage.setItem('token', json.token);
                                this.setState({
                                    logged_in: true,
                                    data: {
                                        first_name: json.user.first_name,
                                        last_name: json.user.last_name,
                                        is_surgeon: !json.user.is_superuser,
                                        id: json.user.id
                                    },
                                    wrong_credentials: false,
                                    password_missing: false,
                                    username_missing: false
                                })
                                window.location.reload()
                            } else this.setState({other_session_detected: true})
                        })
                    }
                })
                .catch(error => {
                    this.setState({loading: false, fetch_error: true})
                })
        } else this.setState({wrong_credentials: false, password_missing: !data.password, username_missing: !data.username})
    };

    handleLogout = () => {
        let selection = window.confirm('Do you really want to log out?');
        if (selection) this.logout()
    };
    logout = () => {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('timer')
        this.setState({
            logged_in: false,
            data: {
                username: '',
                first_name: '',
                last_name: '',
                is_surgeon: false,
                surgeon_id: -1,
            },
            other_session_detected: false,
            wrong_credentials: false,
            password_missing: false,
            username_missing: false,
        })
        return <Redirect to='/login' />;
    }

    render () {
        const is_surgeon = this.state.data.is_surgeon
        return <Suspense fallback="loading">
            <Router>
                {sessionStorage.getItem('token') && this.state.logged_in
                    ? <div className='app'>
                        <NavBar
                            win_size={this.state.win_size}
                            username={this.props.name}
                            handleLogout={this.handleLogout}
                            is_surgeon={is_surgeon}
                        />
                        <SessionTimer start={this.state.logged_in} logout={this.logout}/>
                        <Switch>
                            <ProtectedRoute exact path='/Home'>
                                <Home handleLogout={this.handleLogout}/>
                            </ProtectedRoute>
                            <ProtectedRoute exact path='/NewDiagnosis'>
                                {is_surgeon
                                    ? <Redirect to='/Home'/>
                                    : <Master handleLogout={this.handleLogout}/>
                                }
                            </ProtectedRoute>
                            <ProtectedRoute exact path='/Review'>
                                <ReviewHistory handleLogout={this.handleLogout}/>
                            </ProtectedRoute>
                            <ProtectedRoute exact path='/Viewer'>
                                <ViewerHistory handleLogout={this.handleLogout}/>
                            </ProtectedRoute>
                            <Route path='*'>
                                <Redirect to='/Home' />
                            </Route>
                        </Switch>
                        <Footer />
                    </div>
                    : <Switch>
                        <Route path='/login'>
                            <LoginPage
                                loading={this.state.loading}
                                handle_login={this.handleLogin}
                                other_session_detected={this.state.other_session_detected}
                                wrong_credentials={this.state.wrong_credentials}
                                password_missing={this.state.password_missing}
                                username_missing={this.state.username_missing}
                            />
                        </Route>
                        <Route path='*'>
                            <Redirect to='/login' />
                        </Route>
                    </Switch>
                }
            </Router>
        </Suspense>
    }
}

export default App