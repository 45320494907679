// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    height: var(--footer);
    bottom: 0;
    position: fixed;
    width: 100%;
    background-color: var(--gold);
    display: block;
}
.footer > p, .footer-text {
    padding-top: 2px;
    color: var(--dark-blue);
    font-weight: 600;
    text-align: center;
    font-size: 13px;
}

@media screen and (max-width: 329px) {
    .footer > p {
        padding-top: 2px;
        color: var(--dark-blue);
        text-align: center;
        font-size: 10px;
        line-height: 11px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,SAAS;IACT,eAAe;IACf,WAAW;IACX,6BAA6B;IAC7B,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI;QACI,gBAAgB;QAChB,uBAAuB;QACvB,kBAAkB;QAClB,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":[".footer {\n    height: var(--footer);\n    bottom: 0;\n    position: fixed;\n    width: 100%;\n    background-color: var(--gold);\n    display: block;\n}\n.footer > p, .footer-text {\n    padding-top: 2px;\n    color: var(--dark-blue);\n    font-weight: 600;\n    text-align: center;\n    font-size: 13px;\n}\n\n@media screen and (max-width: 329px) {\n    .footer > p {\n        padding-top: 2px;\n        color: var(--dark-blue);\n        text-align: center;\n        font-size: 10px;\n        line-height: 11px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
