import React from 'react'
import './Home.css'
import {Redirect} from 'react-router-dom'
import {CircularProgress, Grid, Paper} from "@mui/material"
import {GridPatient} from "./GridPatient"
import {getDemoList} from "../../services/HomeService";

const first_titles = ['Sex', 'Age (yo)', 'Height (cm)', 'Weight (kg)']
const titles_bis = ['Aquisition date', 'Diagnostic date']

const widths = {
    'large': [3, 4, 5],
    'medium': [3, 4, 5],
    'small' : [3, 3, 6],
}

export const ivds = ['T8/T9', 'T9/T10', 'T10/T11', 'T11/T12', 'T12/L1', 'L1/L2', 'L2/L3', 'L3/L4', 'L4/L5', 'L5/S1']

const HomeTabTitles = ({win_size}) => {
    return <Grid container className='home-table-titles'>
        <Grid item xs={widths[win_size][0]} style={{display: 'flex'}}><p style={{margin: 'auto'}}>Patient</p></Grid>
        <Grid item xs={widths[win_size][1]}>
            <Grid container style={{height: '100%'}}>
                {titles_bis.map((col, keyCol) => {
                    return <Grid item xs={6} key={keyCol} style={{display: 'flex'}}><p style={{margin: 'auto'}}>{col}</p></Grid>
                })}
            </Grid>
        </Grid>
        <Grid item xs={widths[win_size][2]}>
            <Grid container style={{height: '100%'}}>
                {first_titles.map((col, keyCol) => {
                    return <Grid item xs={3} key={keyCol} style={{display: 'flex'}}><p style={{margin: 'auto'}}>{col}</p></Grid>
                })}
            </Grid>
        </Grid>
    </Grid>
}

const getWinSize = () => {
    const win = window.innerWidth
    if (win <= 650) return 'small'
    else if (win <= 1000) return 'medium'
    else return 'large'
}

export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patients: [],
            patients_ready: true, // TODO: change for production
            no_patients: false,

            add_images: false,
            add_images_patient: -1,
            update_patient: false,
            update_patient_patient: -1,

            review: false,
            review_key: -1,

            small_win: window.innerWidth <= 1000,
            win_size: getWinSize()
        }

        this.controller = new AbortController();
        this.signal = this.controller.signal;
    }

    updateWindowSize = () => {
        this.setState({small_win: window.innerWidth <= 1000})
        this.setState({win_size: getWinSize()})
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateWindowSize)
        getDemoList(this.signal).then(response => {
            if (response.data.length > 0 ) this.setState({patients: response.data, no_patients: false})
            else this.setState({no_patients: true})
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowSize)
    }

    render() {
        if (this.state.add_images) return <Redirect
            to={{
                pathname: '/AddImages',
                state: {'id': this.state.patients[this.state.add_images_patient]['patient']['id']}
            }}
        />
        else if (this.state.update_patient) {
            const patient = this.state.patients[this.state.update_patient_patient]['patient']
            return <Redirect
                to={{
                    pathname: '/UpdatePatient',
                    state: {
                        'id': patient['id'],
                        'identity': patient['clinical_data']['identity'],
                        'history': patient['clinical_data']['med_history'],
                        'work': patient['clinical_data']['daily_life']
                    }
                }}
            />
        } else if (this.state.review) {
            const patient_name = this.state.patients[this.state.review_key]["patient_name"]
            const patient_id = parseInt(patient_name.split('_')[1])
            return <Redirect
                to={{
                    pathname: '/Review',
                    state: {'patient_id': patient_id}
                }}
            />
        } else return <div className='app-content'>
            <Paper id="home-table">
                <HomeTabTitles win_size={this.state.win_size}/>
                {this.state.patients_ready
                    ? <div className='home-container'>
                        {this.state.patients.length > 0 && !this.state.no_patients
                            ? this.state.patients.map((patient, keyPatient) => {
                                return <GridPatient
                                    key={keyPatient}
                                    win_size={this.state.win_size}
                                    widths={widths[this.state.win_size]}
                                    color={keyPatient%2 === 0 ?'var(--medium-gold)' : 'var(--light-gold)'}
                                    patient_id={patient['id']}
                                    patient_name={patient['patient_name']}
                                    aquisition_date={patient['aquisition_date']}
                                    diagnostic_date={patient['date']}
                                    identity={patient['patient_identity']}
                                    diagnostic={patient['diagnostic']}
                                    handleReview={() => this.setState({review: true, review_key: keyPatient})}
                                />
                            })
                            : <div style={{display: 'flex', height: '80px'}}>
                                { this.state.no_patients
                                    ? <p style={{margin: 'auto'}}>No patients</p>
                                    : <CircularProgress style={{margin: 'auto', color: 'var(--gold)'}}/>
                                }
                            </div>
                        }
                    </div>
                    : <div style={{display: 'flex', height: '80px'}}>
                        <CircularProgress style={{margin: 'auto', color: 'var(--gold)'}}/>
                    </div>
                }
            </Paper>
        </div>
    }
}