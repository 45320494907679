import React from 'react'
import PDFPage from "../PDF/PDFPage"
import {Redirect, useLocation} from "react-router-dom"
import {getDemoInfos} from "../../services/ReviewService";

export const ReviewHistory = props => {
    const state = useLocation().state
    const patient_id = state !== undefined ? state.patient_id : null
    return <Review {...props} patient_id={patient_id}/>
}

const Review = ({patient_id, name, handleLogout}) => {
    const [data, setData] = React.useState(null)

    React.useEffect(() => {
        if (patient_id !== undefined || true) {
            getDemoInfos(patient_id).then(response => setData(response.data))
        }
    }, [patient_id])
    if (patient_id === undefined || patient_id === null) return <Redirect to={{pathname: '/Home'}}/>
    else {
        return <div className='app-content'>
            {data !== null
                ? <PDFPage data={data}/>
                : <p>Loading...</p>
            }
        </div>
    }
}