import {Alert, AlertTitle, Button} from "@mui/material";
import React from "react";

export const ErrorComponent = ({error}) => {
    return <div style={{width: '500px', margin: '0 auto', display: 'flex', flexDirection: 'column'}}>
        <Alert severity="error">
            <AlertTitle>Error  {error}</AlertTitle>
            An error occured during diagnosis, <strong>please try again or contact support</strong>
            <br/>
        </Alert>
        <Button
            style={{backgroundColor: 'var(--error-color)', color: 'white', margin: '5px auto'}}
            onClick={() => window.location.reload()}
        >
            Try again
        </Button>
    </div>
}