import React, {Component} from 'react'
import Issues from "./Issues/Issues"
import {getDiagnosis} from "../../services/ImportService"
import {checkForIssues, error401, getResolvedIssues} from "../../utils"
import UploadImages from "./UploadImages/UploadImages"
import {Redirect} from "react-router-dom"
import {putIssuesResolved} from "../../services/IssueService";
import {MasterChoices} from "./MasterChoices";
import './Master.css'

export default class Master extends Component {
    /**
     * STATUS
     *     0: ready for user to upload files
     *     1: files uploaded on frontend, ready to be sent to backend, waiting for user to click on 'START'
     *     2: PUT request (newDemo) : received => status=3 : get info about a new demonstrator instance
     *     3: POST request (postFiles) : received => status=4 : files sent to backend, ready to be sorted
     *     4: POST request (sortingFiles) : received => status=5 : files sorted, ready to look for modalities
     *     5: POST request (getIssues) : received => status=6 : issues found, waiting for user to resolve issues
     *     6: waiting for user to click on 'RESOLVE ISSUES' or 'START DIAGNOSIS'
     *     7: user clicked on 'RESOLVE ISSUES' button => Issues component displayed
     *     8: user clicked on 'START DIAGNOSIS' button => PUT request (issuesResolved) : received => status=9, ready to start diagnosis
     *     9: GET request (diagnosis) : received => status=10
     *     10:
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            status: 0,
            statusError: false,
            diagnosisError: -1,

            detected_series: [],
            files_not_analysed: [],

            issuesInitial: null, //json envoye par le backend - ex: {'coro_stir':'coro_stir', 'inphase_flex':'Sag T2'}
            missingSeries: null, //listes des modalites qui manquent pour le diagnostique - ex: ['Ax T1', 'Sag T1']
            availablefolders: null, //liste de toutes les series disponibles uploadees dans le backend - ex: ['coro_stir', 'inphase_flex']
            IssuesResolved: null, //json renvoye au backend - ex: {'coro_stir':'Sag T1', 'inphase_flex':'Sag T2'}

            file: null,
            urlFile: null,

            id: null,

            data_pdf: {},
            seg_sag: null,
            seg_sag_clean: null,
            seg_ax: null,
            seg_ax_clean: null,

            time_upload_files: null,
            time_sorting_files: null,
            time_get_issues: null,
        }

        this.controller = new AbortController();
        this.signal = this.controller.signal;
    }

    componentWillUnmount() {
        this.controller.abort()
    }

    checkForIssues = dict => {
        this.setState({issuesInitial: dict})
        const result = checkForIssues(dict)
        if (result[0].length >= 1) {
            this.setState({availableFolders: result[1], missingSeries: result[0]})
        } else this.preprocIssuesResolved(dict)
    }

    preprocIssuesResolved = dict => {
        const new_resolved_issues = getResolvedIssues(dict, this.state.issuesInitial)
        this.setState({status: 8})
        this.startIssuesResolved(new_resolved_issues)
    } //status 6 => 8, status 7 => 8

    startIssuesResolved = (issues_resolved) => {
        putIssuesResolved(issues_resolved, this.state.id, this.signal)
            .then(response => {
                if (response.status && response.status === 200) {
                    this.setState({status: 9})
                    this.startDiagnosis()
                }
        }).catch(error => {
            if (error.response && error.response.status === 401) error401()
            else this.setState({statusError: true})
        })
    } //status 8 => 9

    startDiagnosis = () => {
        getDiagnosis(this.state.id, this.state.time_upload_files, this.state.time_sorting_files, this.state.time_get_issues, this.signal)
            .then(response => {
                if (response.status && response.status === 200) this.setState({status: 10, data_pdf: response.data})
            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 401) error401()
                    else this.setState({diagnosisError: error.response.status})
                }
            })
    } //status 9 => 10

    render() {
        return <div className='app-content'>
            {this.state.status < 0
                ? <MasterChoices
                    startUploadPatient={() => this.setState({status: 0})}
                />
                : (this.state.status <= 9 && this.state.status !== 7)
                    ? <UploadImages
                        status={this.state.status}
                        setStatus={status => this.setState({status: status})}
                        statusError={this.state.statusError}
                        setStatusError={() => this.setState({statusError: true})}
                        diagnosisError={this.state.diagnosisError}
                        demo_id={this.state.id}
                        setId={id => this.setState({id: id})}
                        setTimeUploadTime={value => this.setState({time_upload_files: value})}
                        detectedSeries = {this.state.detected_series}
                        setDetectedSeries={series => this.setState({detected_series : series})}
                        setSortingTime={value => this.setState({time_sorting_files: value})}
                        setIssuesTime={value => this.setState({time_get_issues: value})}
                        checkForIssues={this.checkForIssues}
                        startDiagnosis={this.startDiagnosis}
                        missingSeries={this.state.missingSeries}
                        resolveIssues={() => this.setState({status: 7})}
                    />
                    : this.state.status === 7
                        ? <Issues
                            missingSeries={this.state.missingSeries}
                            //missingSeries={["AxT2", "SagT1"]}
                            available={this.state.availableFolders}
                            //available={["AxT2", "AxT1", "AxT23D"]}
                            startIssuesResolved={this.preprocIssuesResolved}
                        />
                        : (this.state.status >= 10 && this.state.data_pdf !== {} && <Redirect
                            to={{pathname: '/Review', state: {'patient_id': this.state.id}}}
                        />)
            }
        </div>
    }
}