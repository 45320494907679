import {withStyles} from "@material-ui/core/styles";
import {Button} from "@mui/material";
import React from "react";

export const NextBackPDFButton = withStyles({
    root: {
        margin: 'auto',
        height: '30px',
        backgroundColor: 'var(--dark-blue)',
        color: 'white',
        width: 'fit-content',
        border : '1px solid var(--dark-blue)',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '1px solid var(--dark-blue)',
            color: 'var(--dark-blue)'
        },
    },
    label: {
        textTransform: "capitalize"
    }
})(props => <Button {...props} />);

export const DownloadPDFButton = withStyles({
    root: {
        margin: 'auto',
        height: '30px',
        padding: '15px 30px',
        backgroundColor: 'var(--dark-gold)',
        color: 'white',
        width: 'fit-content',
        border : '1px solid var(--dark-gold)',
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.25)',
        '&:hover': {
            backgroundColor: 'white',
            border : '1px solid var(--dark-gold)',
            color: 'var(--dark-gold)'
        },
    },
    label: {
        textTransform: "capitalize"
    }
})(props => <Button {...props} />);