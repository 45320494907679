import React from 'react';
import './LoginPage.css'
import {Redirect} from "react-router-dom";
import {CircularProgress} from "@mui/material";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
        };
    }

    handleEnterKey = e => {
        let key = e.keyCode
        if(key === 13) {
            this.props.handle_login(e, this.state)
        }
    }

    handle_change = e => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(prevstate => {
            const newState = { ...prevstate };
            newState[name] = value;
            return newState;
        });
    };

    render() {
        if (sessionStorage.getItem("token")) return <Redirect to="/Home" />
        return (
            <div className='background' onKeyDown={this.handleEnterKey}>
                <div className="items">
                    <img src='/images/BarSup/logo_caerus.png' alt='logo_caerus'/>
                    <form>
                        <p>Username</p>
                        <input
                            type="text"
                            name="username"
                            value={this.state.username}
                            onChange={this.handle_change}
                        />
                        {this.props.username_missing
                            ? <p className='error-message'>You need a username to log in</p>
                            : <></>
                        }
                        <p>Password</p>
                        <input
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handle_change}
                        />
                    </form>
                    {this.props.other_session_detected &&
                        <p className='error-message'>Something went wrong. Please contact the support.</p>}
                    {this.props.wrong_credentials && <p className='error-message'>Wrong username or password</p>}
                    {this.props.password_missing && <p className='error-message'>You need a password to log in</p>}
                    <div className='login-submit'>
                        <button className='submit-button' onClick={e => this.props.handle_login(e, this.state)}>Submit</button>
                        {this.props.loading && <CircularProgress style={{color: 'var(--dark-blue)'}}/>}
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginPage