import {Stack} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import React from "react";

export const UploadProgressBar = ({success, progress}) => {
    return <div className='progress-box'>
        <p>Uploading files {success ? <i className="far fa-check-circle"/> : '...'}</p>
        <Stack sx={{color: 'var(--dark-blue)'}}>
            <LinearProgress
                variant="determinate"
                value={success ? 100 : progress}
                color="inherit"
            />
        </Stack>
    </div>
}

export const SortingProgressBar = ({success}) => {
    return <div className='progress-box'>
        <p>Sorting files {success ? <i className="far fa-check-circle"/> : '...'}</p>
        <Stack sx={{color: 'var(--dark-blue)'}}>
            {success
                ? <LinearProgress
                    variant="determinate"
                    value={100}
                    color="inherit"
                />
                : <LinearProgress
                    color="inherit"
                />
            }
        </Stack>
    </div>
}

export const DetectionProgressBar = ({finished, success}) => {
    return <div className='progress-box'>
        <p>Looking for Sag T1, Sag T2 and Ax T2 {finished
            ? success
                ? <i className="far fa-check-circle"/>
                : <i className="fas fa-exclamation-triangle"/>
            : '...'}
        </p>
        <Stack sx={{color: 'var(--dark-blue)'}}>
            {finished
                ? <LinearProgress
                    variant="determinate"
                    value={success ? 100 : 75}
                    color="inherit"
                />
                : <LinearProgress color="inherit"/>
            }
        </Stack>
    </div>
}