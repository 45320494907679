import {axiosInstance} from "../http-common";

export const getDemoInfos = async (demo_id, signal) => {
    return await axiosInstance.get(`/core/demo/${demo_id}/`, {signal: signal})
}

export const getDemoPDF = async (demo_id, signal) => {
    const path = `${process.env.REACT_APP_IP}/core/demo/${demo_id}/pdf/`
    return await fetch(path, {
        method: 'GET',
        headers: {Authorization: `JWT ${sessionStorage.getItem('token')}`},
        signal
    })
        .then(response => {
            if (response.status === 401) {
                sessionStorage.removeItem('token')
                alert('Your session expired.')
                window.location.reload()
            } else if (response.status === 404) return null
            else return response.blob()
        }).then(my_blob => {
            if (my_blob instanceof Blob) return URL.createObjectURL(my_blob)
            else return null
        })
}

export const getNbSlices = async (demo_id, signal) => {
    return await axiosInstance.get(`/core/demo/${demo_id}/slices/`, {signal: signal})
}

export const getOverlayImg = async (demo_id, sag_ax, mod, slice_id, clean, signal) => {
    const path = `${process.env.REACT_APP_IP}/core/demo/${demo_id}/${sag_ax}/${mod}/${slice_id}/${clean}/`
    return await fetch(path, {
        method: 'GET',
        headers: {Authorization: `JWT ${sessionStorage.getItem('token')}`},
        signal
    })
        .then(response => {
            if (response.status === 401) {
                sessionStorage.removeItem('token')
                alert('Your session expired.')
                window.location.reload()
            } else if (response.status === 404) return null
            else return response.blob()
        }).then(my_blob => {
            if (my_blob instanceof Blob) return URL.createObjectURL(my_blob)
            else return null
        })
}

export const getSagCrop = async (demo_id, view, ivd, signal) => {
    const path = `${process.env.REACT_APP_IP}/core/demo/crops_sag/${demo_id}/${view}/${ivd}/`
    return await fetch(path, {
        method: 'GET',
        headers: {Authorization: `JWT ${sessionStorage.getItem('token')}`},
        signal
    })
        .then(response => {
            if (response.status === 401) {
                sessionStorage.removeItem('token')
                alert('Your session expired.')
                window.location.reload()
            } else if (response.status === 404) return null
            else return response.blob()
        }).then(my_blob => {
            if (my_blob instanceof Blob) return URL.createObjectURL(my_blob)
            else return null
        })
}

export const getAxialCrop = async (demo_id, view, ivd, signal) => {
    const path = `${process.env.REACT_APP_IP}/core/demo/crops_ax/${demo_id}/${view}/${ivd}/`
    return await fetch(path, {
        method: 'GET',
        headers: {Authorization: `JWT ${sessionStorage.getItem('token')}`},
        signal
    })
        .then(response => {
            if (response.status === 401) {
                sessionStorage.removeItem('token')
                alert('Your session expired.')
                window.location.reload()
            } else if (response.status === 404) return null
            else return response.blob()
        }).then(my_blob => {
            if (my_blob instanceof Blob) return URL.createObjectURL(my_blob)
            else return null
        })
}
